import React from 'react';
import SingleColPageLayout from '../templates/single-col-page-layout';
import ContactForm from '../components/ContactForm';
import MetaTags from '../components/MetaTags';

const Page = ({ path }) => {
  // const qs = (window && window.location.search) ? parse(window.location.search) : {};
  const qs = {}; // TODO read qs
  return (
    <SingleColPageLayout>
      <MetaTags title="Contact" path={path} />
      <h1 className="text-center">Contact</h1>
      <div className="max-w-xl mx-auto">
        <p>
          Fill out the form below and I’ll get back to you as soon as possible.
        </p>
        <ContactForm subject={qs.subject} showLinksOnSubmit />
      </div>
    </SingleColPageLayout>
  );
};

export default Page;
